<template>
  <div>
    <BottomPopup :popup-title="$t('page.redeemDialog.title')" :show.sync="show" :show-left-icon="redeemStatus != 0" :show-right-icon="true">
      <template v-if="redeemStatus != 0" v-slot:left-icon>
        <div class="header-icon-btn-container" @click="backHandler">
          <i class="iconfont icon-arrow-left" />
        </div>
      </template>
      <template v-slot:right-icon>
        <div class="header-icon-btn-container" @click="closeDialog">
          <i class="iconfont icon-close-new" />
        </div>
      </template>
      <template v-slot:body>
        <div class="evaluation-redeem-container">
          <template v-if="redeemStatus == 0">
            <ul class="students-list-wrap">
              <li v-for="(item, key) in studentsList" :key="key" class="student-wrap">
                <img class="avatar" v-lazy-load :data-src="item.avatar">
                <div class="name-wrap">
                  <p class="name">
                    {{ getFullName(item) }}
                  </p>
                  <p class="id">
                    No.{{ item.card }}
                  </p>
                </div>
                <span :class="['check-btn', selectUid===item.uid?'selected':'']" @click="chooseUid(item)" />
              </li>
            </ul>
            <div class="evaluation-redeem-form">
              <div class="evaluation-redeem-input ts-input" :class="{'is-error': errorMsg}">
                <div class="input-container">
                  <input v-model="redeemCode" class="input-inner" type="text" :class="{'placeholder-show': !redeemCode}" :placeholder="$t('coursesDetail.redeemQualificationCode.placeholder')" maxlength="11"
                         @change="redeemCodeChangeHandler"
                         @blur="redeemCodeBlurHandler"
                  >
                  <div v-if="errorMsg" class="suffix-container is-error">
                    <i class="iconfont icon-info-circle" />
                  </div>
                  <div v-else class="suffix-container" @click="clearHandler">
                    <i class="iconfont icon-close-new" />
                  </div>
                </div>
                <div class="append-container">
                  <van-button class="button button-small button-default button-apply"
                              :disabled="isAppling" @click="applyHandler"
                  >
                    {{ $t('coursesDetail.redeemQualificationCode.btnText') }}
                  </van-button>
                </div>
                <div class="error-msg" :class="{show: errorMsg}">
                  {{ errorMsg }}
                </div>
              </div>
            </div>
            <div class="instruction-container font-medium">
              <h6 class="title font-medium">
                {{ $t('page.redeemDialog.instructions') }}
              </h6>
              <ul class="instruction-list">
                <li class="instruction-item">
                  {{ $t('page.redeemDialog.instructionItem1') }}
                </li>
                <li class="instruction-item">
                  {{ $t('page.redeemDialog.instructionItem2') }} <a v-if="phone" :href="`tel:${phone}`" @click="$makeCall(phone, $event)">{{ phone }}</a>
                  <a v-else-if="email" :href="`mailto:${email}`">{{ email }}</a>
                </li>
                <li v-if="studentsList.length < 6" class="redeem-lnb">
                  {{ $t('page.couponList.redeemLnb1') }}<a @click="routerSet">
                    {{ $t('page.couponList.redeemLnb2') }}
                  </a>{{ $t('page.couponList.redeemLnb3') }}
                </li>
              </ul>
            </div>
          </template>
          <!-- 兑换成功 -->
          <template v-else-if="redeemStatus >= 1">
            <div class="redeem-success-result-container">
              <div class="recommend-diffculty-level">
                <DifficultyCard :is-recommend="true" :card-data="levelDegree" />
              </div>
              <div class="operate-btns">
                <van-button class="button button-normal" @click="mainBtnClickHandler">
                  {{ resultMainBtnTxt }}
                </van-button>
              </div>
            </div>
          </template>
          <!-- 兑换失败 -->
          <template v-else-if="redeemStatus == -1">
            <div class="exception-container">
              <Exception placeholder-class-name="system-error-img" :tip-text="$t('coursesDetail.redeemQualificationCode.fail',[contactInfo])" />
            </div>
          </template>
        </div>
      </template>
    </BottomPopup>
  </div>
</template>

<script>
import { oneApi } from 'config/api';
import { filterBlank } from 'common/utils.js';
import DifficultyCard from 'components/test/DifficultyCard'
import BottomPopup from 'components/common/BottomPopup'
import Exception from 'components/common/Exception'

export default {
  components: { DifficultyCard, BottomPopup, Exception },
  props: {
    // 组件引用位置
    origin: {
      default: 'normal',
      type: String,
    },
    // 主按钮文案
    mainBtnText: {
      default: '',
      type: String,
    },
    // 课程信息
    courseDetail: {
      default: () => {},
      type: Object,
    },
    studentsList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      show: false,
      errorMsg: '',
      email: this.$t('common.contactEmail'),
      phone: this.$t('common.contactPhone'),
      isAppling: false,
      redeemCode: '',
      redeemStatus: 0,
      result: {},
      levelDegree: {
        levelDegreeName: '',
        levelDegreeOrder: '',
        levelDegreeDesc: '',
      },
      resultMainBtnTxt: this.mainBtnText,
      selectUid: '',
      easternNameOrderEnabled: false,
    }
  },
  computed: {
    contactInfo() {
      return this.phone || this.email
    },
  },
  async mounted() {
    const clientOptions = JSON.parse(window.localStorage.getItem('clientOptions'))
    if (!clientOptions) {
      await this.$store.dispatch('ucenter/getClientOptions', { $axios: this.$axios });
    }
    const { easternNameOrderEnabled } = JSON.parse(window.localStorage.getItem('clientOptions')) || {}
    this.easternNameOrderEnabled = easternNameOrderEnabled || false
  },
  methods: {
    getFullName(item) {
      if (!item) return ''
      if (this.easternNameOrderEnabled) {
        return `${item.lastName || ''}${item.firstName || ''}` || item.nickName
      }
      return (item.firstName || item.lastName) ? `${item.firstName || ''} ${item.lastName || ''}` : item.nickName
    },
    routerSet() {
      this.$jumpFamilyAccount(this.$router)
    },
    chooseUid(item) {
      this.selectUid = item.uid
    },
    init() {
      this.redeemCode = ''
      this.result = {}
      this.redeemStatus = 0
      this.selectUid = this.studentsList[0].uid
    },
    async applyHandler() {
      if (!this.redeemCode) {
        this.errorMsg = this.$t('coursesDetail.redeemQualificationCode.errorMsg')
        return
      }
      this.errorMsg = ''
      this.isAppling = true
      const data = {
        code: this.redeemCode,
      }

      this.evaluationRedeemClick()
      const res = await this.$axios.post(oneApi.applyEvaluationRedeem, data, { rewritePostBody: true, uid: this.selectUid })

      if (res.code !== 0) {
        // 兑换失败
        this.redeemStatus = -1
        this.isAppling = false
        this.$Toast(res.msg)
        return
      }
      const resData = res.data
      // 兑换码已兑换
      if (resData.isRedeem) {
        this.redeemStatus = 2
        if (this.origin === 'registration') {
          this.resultMainBtnTxt = this.$t('coursesDetail.redeemQualificationCode.check')
        }
      } else {
        // 兑换成功
        this.redeemStatus = 1
        if (this.origin === 'registration') {
          this.resultMainBtnTxt = this.$t('coursesDetail.redeemQualificationCode.continue')
        }
      }

      this.levelDegree.levelDegreeName = resData.levelDegreeName
      this.levelDegree.levelDegreeOrder = resData.levelDegreeOrder
      this.levelDegree.levelDegreeDesc = this.getlevelDegreeDesc(resData.isRedeem, resData.subjectName, resData.gradeList)

      this.result = { ...resData }

      this.isAppling = false
    },
    getlevelDegreeDesc(isRedeem, subjectName, grades) {
      const gradeStr = grades.map((item) => item.gradeName).join(',')
      if (isRedeem) return this.$t('coursesDetail.redeemQualificationCode.levelDegreeDesc[0]', [gradeStr, subjectName])
      return this.$t('coursesDetail.redeemQualificationCode.levelDegreeDesc[1]', [gradeStr, subjectName])
    },
    mainBtnClickHandler() {
      this.$emit('main-click', {
        isRedeem: this.result.isRedeem,
        redeemCode: this.redeemCode,
        uid: this.selectUid,
      })
    },
    redeemCodeChangeHandler() {
      if (this.redeemCode) {
        this.errorMsg = ''
      }
    },
    redeemCodeBlurHandler() {
      this.redeemCode = filterBlank(this.redeemCode)
    },
    backHandler() {
      this.init()
    },
    openDialog() {
      this.show = true
      this.init()
    },
    closeDialog() {
      this.show = false
    },
    clearHandler() {
      this.redeemCode = ''
    },
    // 神策埋点-诊断兑换码兑换点击事件
    evaluationRedeemClick() {
      const routeName = this.$route.name
      let source = ''
      switch (routeName) {
        case 'courses-detail-coursesId':
          source = '班级详情页'
          break;
        case 'test-test-results':
          source = 'test result'
          break;
        default: break
      }
      const data = {
        code_id: this.redeemCode,
        previous_source: source,
      }

      if (routeName === 'courses-detail-coursesId') {
        data.course_id = this.courseDetail.spec.courseId;
        data.class_id = this.courseDetail.spec.clazzId;
      }

      this.Sensors.track('qualification_code_apply', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.redeem-lnb {
  font-size: 12px;
  font-family: PingFangSC-Heavy, PingFang SC, Arial, Helvetica, sans-serif;
  font-weight: 800;
  color: var(--txt-color-lv2);
  line-height: 14px;
  margin-top: 16px;

  a {
    color: var(--txt-color-link);
    text-decoration: underline;
  }
}

.students-list-wrap {
  background-color: #fff;
  width: 100%;
  border-radius: 4px;
  padding: 0;
  margin-bottom: 20px;
  max-height: 210px;
  overflow-y: scroll;

  .student-wrap {
    border-bottom: 1px solid #fafafa;
    list-style: none;
    display: flex;
    padding: 16px;
    align-items: center;

    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      border: 2px solid #f4f6fa;
      border-radius: 50%;
    }

    .name-wrap {
      flex: auto;

      .name {
        font-size: 14px;
        color: #222;
        font-weight: bold;
        margin-bottom: 6px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 220px;
        overflow: hidden;
      }

      .id {
        color: #a7a7a7;
        font-size: 12px;
      }
    }

    .check-btn {
      cursor: pointer;
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 2px solid var(--txt-color-link);
      border-radius: 50%;

      &.selected {
        border: 0 none;
        background: url('~assets/images/coupon/icon-selected.png') 0 0 / 100% auto no-repeat;
      }
    }
  }
}

.header-icon-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: px2vw(64);
  height: px2vw(64);
  text-align: center;
  border-radius: 50%;
  color: var(--txt-color-lv2);
  background: #f4f6fa;

  .iconfont {
    font-size: px2vw(40);
  }
}

.evaluation-redeem-container {
  .evaluation-redeem-form {
    .ts-input {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: px2vw(12);
      width: auto;
      border-radius: px2vw(4);
      background: #fff;
      border: 1px solid #e6e6e6;

      .input-container {
        display: flex;
        align-items: center;
        width: 100%;

        input {
          flex-grow: 1;
          padding: px2vw(16) px2vw(20);
          width: 100%;
          font-size: px2vw(36);
          line-height: px2vw(48);
          font-weight: 500;
          color: var(--txt-color-lv3);
          border: none;

          &:focus-visible {
            outline: none;
            border: none;
          }

          &:focus {
            outline: none;
            border: none;
            color: var(--txt-color-lv3);
          }

          &::placeholder {
            color: #dee2e7;
          }
        }

        .suffix-container {
          flex-shrink: 0;
          display: block;
          visibility: visible;
          margin: 0 px2vw(20) 0 auto;
          width: px2vw(40);
          height: px2vw(40);
          text-align: center;
          color: #fff;
          border-radius: 50%;
          background: #8b95a6;
          cursor: pointer;
          opacity: 1;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

          .iconfont {
            line-height: px2vw(40);
          }

          &.is-error {
            background: #ff503f;
            cursor: default;
          }
        }

        .input-inner.placeholder-show + .suffix-container {
          visibility: hidden;
          opacity: 0;
        }
      }

      &.is-error .input-container .input-inner + .suffix-container {
        visibility: visible;
        background: transparent;
        opacity: 1;

        .iconfont {
          font-size: px2vw(40);
          line-height: px2vw(40);
          color: #ff5040;
        }
      }

      .error-msg {
        position: absolute;
        bottom: px2vw(-38);
        left: px2vw(20);
        font-size: px2vw(24);
        line-height: px2vw(28);
        font-weight: 500;
        color: #ff503f;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        &.show {
          visibility: visible;
          opacity: 1;
        }

        &::before {
          content: '*';
        }
      }
    }

    .evaluation-redeem-input {
      margin: 0 auto;
      width: 100%;

      .button-apply {
        width: px2vw(160);
      }
    }
  }

  .instruction-container {
    margin: px2vw(40) auto;
    width: 100%;
    color: var(--txt-color-lv2);

    .title {
      margin-bottom: px2vw(24);
      font-size: px2vw(28);
      line-height: px2vw(32);
      color: var(--txt-color-lv2);
    }

    ul {
      padding: 0;
      list-style: none;
    }

    ul > li {
      padding-left: px2vw(8);
      margin: px2vw(10) 0;
      font-size: px2vw(24);
      line-height: px2vw(28);

      &::before {
        content: '-';
      }

      a {
        color: var(--txt-color-link);
        text-decoration: underline;
      }
    }
  }

  .redeem-success-result-container {
    .recommend-diffculty-level {
      margin: px2vw(40) auto;
      padding: px2vw(40) px2vw(32);
      width: 100%;
      border-radius: px2vw(20);
      background: #fff;
    }

    .operate-btns {
      position: absolute;
      left: px2vw(32);
      right: px2vw(32);
      bottom: px2vw(48);

      .button {
        margin: 0 auto;
        width: 100%;
      }
    }
  }

  .exception-container {
    padding-top: px2vw(180);
    word-break: keep-all;

    /deep/ .tip-text {
      margin: px2vw(10) auto 0;
      width: 100%;
    }
  }
}
</style>
